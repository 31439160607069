import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import EPlatformHeader from "../../../components/basic/EPlatformHeader";
import "../../auth/Register/Register.css";
import { Button, Col, Image, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { country_list, role } from "../../auth/Register/AllRegiPageLists";
import { getURLbyEndPoint } from "../../../store/api";
import infoImg from "../../../assets/infoimg.png";
import {
  FaEnvelope,
  FaExternalLinkAlt,
  FaPhone,
  FaWhatsapp,
} from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { AiFillCheckCircle } from "react-icons/ai";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import toast from "react-hot-toast";
import axios from "axios";
import { Profession } from "../../sideBarPage/EventsPages/B2bEventRequest";
import { IoMdContact } from "react-icons/io";
import collaborationRegisterQR from "../../../assets/collaborationRegisterQR.png"

const CollaborationSignup = () => {
  const [registerFormSuccess, setRegisterFormSuccess] = useState(false);
  const [formSuccessful, setFormSuccessful] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [captchaValue, setCaptchaValue] = useState("");
  const [isCaptchaCorrect, setIsCaptchaCorrect] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const captcha_length = 6;
  const initialFormData = {
    selectMembership: "Collaboration",
    companyName: "",
    businessCategory: "",
    inputNumber: "",
    gstNo: "",
    address1: "",
    address2: "",
    city: "",
    country: "",
    state: "",
    pinCode: "",
    firstName: "",
    surName: "",
    role: "",
    email: "",
    password: "",
    phoneNo: "",
    telephoneNo: "",
    reqType: "collaborationReq",
    isExisting: true,
    isTrial: false,
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleConfirmChange = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);
  };

  const handleCaptchaChange = (evt) => {
    setCaptchaValue(evt.target.value);
    if (validateCaptcha(evt.target.value, false)) {
      setIsCaptchaCorrect(true);
      return;
    }
    setIsCaptchaCorrect(false);
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setCaptchaValue("");
    setIsCaptchaCorrect(false);
    setRegisterFormSuccess(false);
    setFormSuccessful(false);
  };

  const handleRegiFormSubmit = async (e) => {
    e.preventDefault();
    setRegisterFormSuccess(true);
    try {
      formData.phoneNo = parseInt(formData.phoneNo);
      if (isFormValid) {
      } else {
        alert("CAPTCHA is invalid");
        return false;
      }

      const validateField = (field, fieldName, regex) => {
        if (
          field === undefined ||
          (typeof field === "string" && field.trim() === "")
        ) {
          console.log("Invalid Form, field:", field);
          alert(`Invalid Form, ${fieldName} can not be empty`);
          return false;
        }
        if (regex && !regex.test(field)) {
          console.log("Invalid Form, field:", field);
          alert(`Invalid Form, ${fieldName} is not in the correct format`);
          return false;
        }
        return true;
      };

      const validateNonZeroNumber = (value, fieldName) => {
        if (parseInt(value) === 0) {
          console.log(`Invalid Form, ${fieldName} cannot be 0`);
          alert(`Invalid Form, ${fieldName} cannot be 0`);
          return false;
        }
        return true;
      };

      // Validate password
      const validatePassword = (password) => {
        if (password === undefined || typeof password !== "string") {
          return false;
        }
        const passwordRegex =
          /^(?=.*[a-z])(?=.*\d)(?=.*[A-Z]?)(?=.*[!@#$%^&*()_+[\]{}|;:',.<>?~\\/-]).{8,15}$/;
        return passwordRegex.test(password);
      };

      // Validate common fields
      if (!validateField(formData.firstName, "firstName")) return;
      if (!validateField(formData.surName, "LastName")) return;
      if (!validateField(formData.email, "Email")) return;
      if (!validateField(formData.reqType, "Req path")) return;
      if (
        !validateField(
          formData.phoneNo.toString(),
          "Phone No.",
          /^(?:\d{10,15})$/
        )
      )
        return;
      if (!validateNonZeroNumber(formData.phoneNo, "Phone No.")) return;
      if (!validateField(formData.companyName, "Company Name")) return;
      if (!validateField(formData.inputNumber, "IEC CODE / CIN NO")) return;
      if (!validateField(formData.gstNo, "Industry")) return;
      if (!validateField(formData.businessCategory, "business Category"))
        return;
      if (!validateField(formData.address1, "address 1")) return;
      if (!validateField(formData.address2, "address 2")) return;
      if (!validateField(formData.city, "city")) return;
      if (!validateField(formData.country, "country")) return;
      if (!validateField(formData.state, "state")) return;
      if (!validateField(formData.pinCode, "pincode")) return;
      if (!validateField(formData.role, "role")) return;

      formData.pinCode = parseInt(formData.pinCode);

      if (!validatePassword(formData.password)) {
        alert(
          "Password must be 8-15 characters, contain at least one lowercase letter, one number, and may include one uppercase letter and one special character."
        );
        return;
      }
      if (!validateField(confirmPassword, "Confirm Password")) return;
      if (formData.password !== confirmPassword) {
        alert("Passwords do not match");
        return;
      }

      const res = await axios.post(getURLbyEndPoint("createClient"), {
        ...formData,
      });

      if (res && res.data && res.data.status) {
        toast.success(res.data.message);
        setFormSuccessful(true);
        setRegisterFormSuccess(false);
        setFormData(initialFormData);
      } else {
        console.log("Response data:", res);
        toast.error(res?.data?.message || "An error occurred");
      }
    } catch (e) {
      console.log("Error caught:", e);
      toast.error(e?.response?.data?.message || "An error occurred");
    } finally {
      setRegisterFormSuccess(false);
    }
  };

  useEffect(() => {
    const isValid = isCaptchaCorrect;
    setIsFormValid(isValid);
  }, [isCaptchaCorrect]);

  useEffect(() => {
    loadCaptchaEnginge(captcha_length);
  }, []);
  return (
    <>
      <Layout>
        <EPlatformHeader heading="Collaborator Sign-up Form" />
        <div className="custom-width mx-auto my-4">
          <Row className="mt-2">
            <Col sm={12} lg={6}>
              <h4 className="montserratAlternatesFont mt-5 fw-semibold">
                Dear Collaborator,
              </h4>
              <h5 className="montserratAlternatesFont my-3 fw-semibold">
                To ensure a seamless experience and keep you well-informed about
                the various activities being undertaken by AECCI, we kindly
                request that you sign up through our e-platform.
              </h5>
              <h5 className="montserratAlternatesFont my-3 fw-semibold">
                By signing up, you'll gain access to the ePlatform, wherein your
                profile is visible. Also, here you can see the Copy of
                Co-operation Agreement and the Certificate of Collaboration
                active in your profile. all this will be kept secured in your
                digital account on the platform
              </h5>

              <h4 className="fw-semibold mt-4">
                Signing-up will enable you to have the updates about:
              </h4>
              <ul style={{ fontSize: "1.2rem" }}>
                <li className="my-2">
                  <span className="fw-semibold">
                    Our Mega Event AECCI B2B Connect 2024.
                  </span>
                </li>
                <li className="my-2">
                  <span className="fw-semibold">
                    Client queries that they want to be addressed during the
                    event.
                  </span>
                </li>
                <li className="my-2">
                  <span className="fw-semibold">
                    You will be able to register online as DELEGATE for the
                    event.
                  </span>
                </li>
                <li className="my-2">
                  <span className="fw-semibold">
                    Top at all, this will showcase the list of the companies
                    registered to meet you during the event.
                  </span>
                </li>
              </ul>

              <h5 className="fw-semibold my-4">
                We are sure that the signing-up will create more visibility and
                transparency of the association.
              </h5>
              <h5 className="fw-semibold my-4">
                Please register at your earliest convenience to stay updated and
                take full advantage of the features available on our platform.
              </h5>

              <div>
                <div className="fw-semibold mb-3 fs-5">
                  If you have any questions or need further clarification, feel
                  free to reach out to our team:
                </div>
                <Row className="mt-3">
                  <Col md={4} xs={4}>
                    <img src={collaborationRegisterQR} className="img-fluid" style={{width: "90%"}} />
                  </Col>
                  <Col md={8} xs={12}>
                    <div className="d-flex my-2 gap-2">
                      <IoMdContact size={25} />
                      <div>
                        <div className="fw-semibold"> Mrs. Swarn Dhiman, ( Executive Director )</div>
                        <div>( AECCI B2B Connect Event Team Leader )</div>
                       

                      </div>
                    </div>
                    <div className="d-flex my-2 gap-2">
                      <IoMdContact size={25} />
                      <div>
                        <div className="fw-semibold">Ms. Rohini P. K.</div>
                        <div>( Region-Asia and Africa Countries )</div>
                      </div>
                    </div>
                    <div className="d-flex my-2 gap-2">
                      <IoMdContact size={25} />
                      <div>
                        <div className="fw-semibold">Ms. Akshata Mulik</div>
                        <div>
                          ( Region-North America and South America Countries )
                        </div>
                      </div>
                    </div>
                    <div className="d-flex my-2 flex-wrap align-items-center gap-2">
                      <MdEmail size={25} />
                      <div className="fw-semibold">
                        Email:{" "}
                        <a
                          href="mailto:legal@aecci.org.in"
                          className="text-decoration-none"
                        >
                          legal@aecci.org.in
                        </a>
                      </div>
                    </div>
                    <div className="d-flex my-2 flex-wrap align-items-center gap-2">
                      <FaPhone
                        size={25}
                        style={{ transform: "rotate(90deg)" }}
                      />
                      <div className="fw-semibold">
                        Handphone:{" "}
                        <a
                          href="tel:+918433720996"
                          className="text-decoration-none"
                        >
                          0091-8433720996
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>

                <div className="mt-3 fw-semibold">
                  Thank you for your cooperation, and we look forward to your
                  active participation!
                </div>
                <div className="my-1">Best regards,</div>
              </div>
            </Col>
            <Col sm={12} lg={6}>
              {!formSuccessful ? (
                <div
                  className="text-white montserratFont"
                  style={{
                    background: "#021C43",
                    paddingBlock: "30px",
                    paddingInline: "50px",
                  }}
                >
                  <form onSubmit={handleRegiFormSubmit}>
                    <p className="mt-4" style={{ fontSize: "0.9em" }}>
                      <span className="fs-6 fw-semibold">
                        fill all fields as per AECCI records
                      </span>
                      <br />* indicates required fields
                    </p>
                    <div className="my-4">
                      <Row>
                        <Col xs={12} sm={12} lg={12}>
                          <label
                            className="fw-bold"
                            style={{ fontSize: "1rem" }}
                          >
                            Username / Email Address *
                          </label>
                          <div className="d-flex flex-column">
                            <input
                              style={{ width: "auto", height: "auto" }}
                              className="form-control rounded-0"
                              onChange={handleInputChange}
                              value={formData.email}
                              name="email"
                              type="email"
                              maxLength={40}
                              required
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className="my-4">
                      <Row>
                        <Col>
                          <label
                            className="fw-bold"
                            style={{ fontSize: "1rem" }}
                          >
                            Password *
                          </label>
                          <input
                            style={{ height: "auto" }}
                            className="form-control rounded-0"
                            onChange={handleInputChange}
                            value={formData.password}
                            name="password"
                            type="password"
                            maxLength={15}
                            required
                          />
                        </Col>
                        <Col>
                          <label
                            className="fw-bold"
                            style={{ fontSize: "1rem" }}
                          >
                            Confirm Password *
                          </label>
                          <input
                            style={{ height: "auto" }}
                            className="form-control rounded-0"
                            onChange={handleConfirmChange}
                            value={confirmPassword}
                            name="confirmPassword"
                            type="text"
                            maxLength={15}
                            required
                          />
                        </Col>
                      </Row>
                    </div>

                    <div className="my-4">
                      <Row>
                        <Col md={12}>
                          <div>
                            <label
                              className="fw-bold"
                              style={{ fontSize: "1rem" }}
                            >
                              Company / Organization Name *
                            </label>
                            <div className="d-flex flex-column">
                              <input
                                style={{ width: "auto", height: "auto" }}
                                className="form-control rounded-0"
                                onChange={handleInputChange}
                                value={formData.companyName}
                                name="companyName"
                                type="text"
                                maxLength={50}
                                required
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className="my-4">
                      <Row>
                        <Col xs={12} sm={12} lg={6}>
                          <label
                            className="fw-bold"
                            style={{ fontSize: "1rem" }}
                          >
                            First Name *{" "}
                          </label>
                          <div className="d-flex flex-column">
                            <input
                              style={{ width: "auto", height: "auto" }}
                              className="form-control rounded-0"
                              onChange={handleInputChange}
                              value={formData.firstName}
                              name="firstName"
                              type="text"
                              maxLength={30}
                              required
                            />
                          </div>
                        </Col>
                        <Col xs={12} sm={12} lg={6}>
                          <label
                            className="fw-bold"
                            style={{ fontSize: "1rem" }}
                          >
                            Last Name *
                          </label>
                          <div className="d-flex flex-column">
                            <input
                              style={{ width: "auto", height: "auto" }}
                              className="form-control rounded-0"
                              onChange={handleInputChange}
                              value={formData.surName}
                              name="surName"
                              type="text"
                              maxLength={30}
                              required
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className="my-4">
                      <Row>
                        <Col xs={6} sm={6} md={6} lg={6}>
                          <div>
                            <label
                              className="fw-bold"
                              style={{ fontSize: "1rem" }}
                            >
                              Professional Category *
                            </label>
                            <div className="d-flex flex-column">
                              <select
                                className="form-control rounded-0"
                                required
                                value={formData.businessCategory}
                                onChange={handleInputChange}
                                name="businessCategory"
                              >
                                <option value="">Select category...</option>
                                {Profession.map((data, index) => (
                                  <option key={index} value={data}>
                                    {data}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6}>
                          <label
                            className="fw-bold"
                            style={{ fontSize: "1rem" }}
                          >
                            Role *
                          </label>
                          <div className="d-flex flex-column">
                            <select
                              className="form-control rounded-0"
                              required
                              value={formData.role}
                              onChange={handleInputChange}
                              name="role"
                            >
                              <option value="">Select role...</option>
                              {role.map((data, index) => (
                                <option key={index} value={data}>
                                  {data}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className="my-4">
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <label
                            className="fw-bold"
                            style={{ fontSize: "1rem" }}
                          >
                            Address 1 *{" "}
                          </label>
                          <div className="d-flex flex-column">
                            <input
                              style={{ width: "auto", height: "auto" }}
                              className="form-control rounded-0"
                              onChange={handleInputChange}
                              value={formData.address1}
                              name="address1"
                              type="text"
                              maxLength={50}
                              required
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className="my-4">
                      <Row>
                        <Col xs={6} sm={6} lg={6}>
                          <label
                            className="fw-bold"
                            style={{ fontSize: "1rem" }}
                          >
                            Address 2 *
                          </label>
                          <div className="d-flex flex-column">
                            <input
                              style={{ width: "auto", height: "auto" }}
                              className="form-control rounded-0"
                              onChange={handleInputChange}
                              value={formData.address2}
                              name="address2"
                              type="text"
                              maxLength={40}
                              required
                            />
                          </div>
                        </Col>
                        <Col xs={6} sm={6} lg={6}>
                          <label
                            className="fw-bold"
                            style={{ fontSize: "1rem" }}
                          >
                            Country *
                          </label>
                          <div className="d-flex flex-column">
                            <select
                              className="form-control rounded-0"
                              required
                              value={formData.country}
                              onChange={handleInputChange}
                              name="country"
                            >
                              <option value="">Select country...</option>
                              {country_list.map((data, index) => (
                                <option key={index} value={data}>
                                  {data}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className="my-4">
                      <Row>
                        <Col xs={12} sm={12} lg={6}>
                          <label
                            className="fw-bold"
                            style={{ fontSize: "1rem" }}
                          >
                            City *
                          </label>
                          <div className="d-flex flex-column">
                            <input
                              style={{ width: "auto", height: "auto" }}
                              className="form-control rounded-0"
                              onChange={handleInputChange}
                              value={formData.city}
                              name="city"
                              type="text"
                              maxLength={30}
                              required
                            />
                          </div>
                        </Col>

                        <Col xs={6} sm={6} lg={3}>
                          <label
                            className="fw-bold"
                            style={{ fontSize: "1rem" }}
                          >
                            State *
                          </label>
                          <div className="d-flex flex-column">
                            <input
                              style={{ width: "auto", height: "auto" }}
                              className="form-control rounded-0"
                              onChange={handleInputChange}
                              value={formData.state}
                              name="state"
                              type="text"
                              maxLength={30}
                              required
                            />
                          </div>
                        </Col>
                        <Col xs={6} sm={6} lg={3}>
                          <label
                            className="fw-bold"
                            style={{ fontSize: "1rem" }}
                          >
                            Pincode *
                          </label>
                          <div className="d-flex flex-column">
                            <input
                              style={{ width: "auto", height: "auto" }}
                              className="form-control rounded-0"
                              onChange={handleInputChange}
                              value={formData.pinCode}
                              name="pinCode"
                              type="text"
                              minLength={3}
                              maxLength={10}
                              required
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className="my-4">
                      <Row>
                        <Col xs={6} sm={6} lg={6}>
                          <label
                            className="fw-bold"
                            style={{ fontSize: "1rem" }}
                          >
                            Phone No *
                          </label>
                          <div className="d-flex flex-column">
                            <input
                              style={{ width: "auto", height: "auto" }}
                              className="form-control rounded-0"
                              onChange={handleInputChange}
                              value={formData.phoneNo}
                              name="phoneNo"
                              type="text"
                              maxLength={15}
                              required
                            />
                          </div>
                        </Col>
                        <Col xs={6} sm={6} lg={6}>
                          <label
                            className="fw-bold"
                            style={{ fontSize: "1rem" }}
                          >
                            Telephone
                          </label>
                          <div className="d-flex flex-column">
                            <input
                              style={{ width: "auto", height: "auto" }}
                              className="form-control rounded-0"
                              onChange={handleInputChange}
                              value={formData.telephoneNo}
                              name="telephoneNo"
                              type="text"
                              maxLength={15}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="my-4">
                      <Row>
                        <Col>
                          <label
                            className="fw-bold text-nowrap"
                            style={{ fontSize: "1rem" }}
                          >
                            Registration No *   
                            <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="button-tooltip-2">Enter Number of your Law firm Registration in bar association of your country.</Tooltip>}
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center p-0"
                          style={{ backgroundColor: 'transparent', border: 'none' }}
                        >
                          <Image ref={ref}
                            roundedCircle src={infoImg}  style={{ height: "4vh", filter: "invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)" }}alt="" />
                        </Button>
                      )}
                    </OverlayTrigger>
                          </label>
                          <input
                            style={{ height: "auto" }}
                            className="form-control rounded-0"
                            onChange={handleInputChange}
                            value={formData.inputNumber}
                            name="inputNumber"
                            type="text"
                            maxLength={30}
                            required
                          />
                        </Col>
                        <Col>
                          <label
                            className="fw-bold"
                            style={{ fontSize: "1rem" }}
                          >
                            AECCI Collaboration No *

                            <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="button-tooltip-2">Please Refer to Collaboration Agreement Reference number</Tooltip>}
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center p-0"
                          style={{ backgroundColor: 'transparent', border: 'none' }}
                        >
                          <Image ref={ref}
                            roundedCircle src={infoImg}  style={{ height: "4vh", filter: "invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)" }}alt="" />
                        </Button>
                      )}
                    </OverlayTrigger>
                          </label>
                          <input
                            style={{ height: "auto" }}
                            className="form-control rounded-0"
                            onChange={handleInputChange}
                            value={formData.gstNo}
                            name="gstNo"
                            type="text"
                            minLength={5}
                            maxLength={30}
                            required
                          />
                        </Col>
                      </Row>
                    </div>

                    <div className="d-flex  justify-content-between align-items-center mb-3">
                      <div className="d-flex align-items-start gap-2 text-white">
                        <LoadCanvasTemplate className="text-white" />
                        {isCaptchaCorrect && (
                          <AiFillCheckCircle className="icon-size text-success" />
                        )}{" "}
                      </div>

                      <div className="w-50">
                        <input
                          className="w-100 px-2 py-1 border-1"
                          placeholder="Type the given captcha word"
                          id="user_captcha_input"
                          name="user_captcha_input"
                          type="text"
                          maxLength={captcha_length}
                          onChange={handleCaptchaChange}
                          value={captchaValue}
                        />
                        {isCaptchaCorrect ? (
                          <p className="text-success">CAPTCHA is valid</p>
                        ) : (
                          <p className="text-danger">CAPTCHA is invalid</p>
                        )}
                      </div>
                    </div>
                    <Row className="d-flex justify-content-center">
                      <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex justify-content-center mb-2"
                      >
                        <Button
                          variant="danger"
                          className="px-5 py-2 rounded-1 border-0 fw-medium"
                          onClick={resetForm}
                        >
                          Reset
                        </Button>
                      </Col>
                      <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex justify-content-center mb-2"
                      >
                        <Button
                          type="submit"
                          className="px-5 py-2 rounded-1 border-0 fw-medium"
                          disabled={registerFormSuccess}
                        >
                          {!registerFormSuccess ? "Register" : "Processing..."}
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </div>
              ) : (
                <div
                  className=""
                  style={{
                    background: "#021C43",
                    paddingInline: "50px",
                    paddingBlock: "50px",
                  }}
                >
                  <div style={{ marginTop: "10px", color: "#fff" }}>
                    <h2>Thank You for Your Request!</h2>
                    <p>
                      We have received your request and will contact you soon.
                    </p>
                    <p>
                      Meanwhile, feel free to explore our{" "}
                      <a
                        style={{ color: "#35afd3" }}
                        target="_blank"
                        href="https://www.aecci.org.in/e-platform/e-platform-information/"
                        rel="noreferrer"
                      >
                        e-platform
                      </a>{" "}
                      and{" "}
                      <a
                        style={{ color: "#35afd3" }}
                        target="_blank"
                        href="https://www.aecci.org.in/our-services/"
                        rel="noreferrer"
                      >
                        services
                      </a>{" "}
                      to discover more about what we offer.
                    </p>
                    <div className="mt-5 mb-3">
                      <h2 className="mb-2">AECCI e-Platform 2.0</h2>
                      <p>
                        The AECCI e-Platform, launched in 2020 and upgraded to
                        Version 2.0 on April 1, 2024, offers digital services
                        tailored for exporters. Exclusively available to
                        registered members, it provides comprehensive support
                        including brand awareness, business opportunities,
                        network expansion, personnel training, and access to
                        international markets.
                      </p>
                    </div>

                    <div className="text-white my-4">
                      <div className="text-white mb-2">
                        <h4 className="card-title">AECCI B2B Connect 2024</h4>
                        <p className="card-text">
                          Join our big event in October 2024 to connect with 25+
                          foreign partners, addressing your business needs
                          through consultations, market studies, business
                          matches, and more.
                        </p>
                        <p className="card-text">
                          Get ready to meet foreign experts and receive support
                          from the comfort of your business.
                        </p>
                      </div>

                      <div className="text-white my-4">
                        <div className="text-white mb-2">
                          <h4>OUR Publications</h4>
                          <p>
                            Access all chamber publications in our Digital
                            Library:
                          </p>
                          <ul style={{ color: "#35afd3" }}>
                            <li>
                              <a
                                style={{ color: "#35afd3" }}
                                target="_blank"
                                href="https://www.aecci.org.in/aecci-viewpoints/"
                                rel="noreferrer"
                              >
                                Daily Viewpoints
                              </a>
                            </li>
                            <li>
                              <a
                                style={{ color: "#35afd3" }}
                                target="_blank"
                                href="https://www.aecci.org.in/media/e-newsletters/"
                                rel="noreferrer"
                              >
                                Newsletters
                              </a>
                            </li>
                            <li>
                              <a
                                style={{ color: "#35afd3" }}
                                target="_blank"
                                href="https://www.aecci.org.in/media/publications/"
                                rel="noreferrer"
                              >
                                Annual Magazine
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  );
};

export default CollaborationSignup;
