import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import Layout2 from "../../../components/Layouts/Layouts/Layout2";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import dummyMembershipCertificate from "../../../assets/DummyMembershipCertificate.png";
import dummyCollaborationCertificate from "../../../assets/DummyCollaborationCertificate.jpg";
import AccordianSection from "../../../components/basic/Accordion";
import { getAuthUserlocal } from "../../../store/services";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import { BsEye } from "react-icons/bs";
import axios from "axios";
import { getURLbyEndPoint } from "../../../store/api";

const MembershipServices = () => {
  const [pdfUrl, setPdfUrl] = useState("");
  const [isHovered, setHovered] = useState(false);
  const [daysLeft, setDaysLeft] = useState(null);
  const membershipType = getAuthUserlocal().selectMembership;
  const validUpto = getAuthUserlocal().validUpTo;

  //View Certificate modal-------------------------------
  // ---------------------------------------------------------
  // ----------------------------------------------------------
  const [showCertificateModal, setShowCertificateModal] = useState(false);
  const [certificateUrl, setCertificateUrl] = useState("");

  // Function to open the modal and set certificate URL
  const openCertificateModal = (certificateUrl) => {
    setCertificateUrl(certificateUrl);
    setShowCertificateModal(true);
  };

  // Function to close the modal
  const closeCertificateModal = () => {
    setShowCertificateModal(false);
  };

  //renew mebership
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [proccedPaymentFlag, setProccedPaymentFlag] = useState(true);

  // //memberhsip renewal form is here------------------------
  // ---------------------------------------------------------
  // ---------------------------------------------------------
  const handleMembRenew = async () => {
    const validationRulesProceedPayment = {
      totalAmount: {
        message: "Please Enter Amount.",
        isValid: () => totalAmount !== 0,
      },
    };

    for (const field in validationRulesProceedPayment) {
      const { message, isValid } = validationRulesProceedPayment[field];
      if (!isValid()) {
        alert(message);
        return;
      }
    }

    // Convert totalAmount to integer
    const integerAmount = parseInt(totalAmount, 10);

    //validation for low balance
    if (getAuthUserlocal().balance - totalAmount < 500) {
      alert("Low Balance!!!");
      return;
    }

    const apiFormData = new FormData();

    //making form
    apiFormData.append("noOfYears", noOfYears);
    apiFormData.append("amount", totalAmount);

    const userId = getAuthUserlocal()._id;

    try {
      setProccedPaymentFlag(false);

      const res = await axios.post(
        getURLbyEndPoint("renewMembership") + userId,
        apiFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.data.status) {
        alert(res.data.message);
        handleClose();
      } else {
        alert("Something went Wrong!");
      }
    } catch (error) {
      if (error.response.status === 409) {
        alert(error.response.data.message);
      } else {
        alert("Server is not responding!!");
      }
      if (error.response) {
        console.error("Response error:", error.response.data);
        console.error("Status code:", error.response.status);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    } finally {
      // Re-enable the button regardless of success or failure
      setProccedPaymentFlag(true);
    }
  };

  // caculating total fees

  const annualFees = {
    "Small Business (Associate)": 6000,
    "Start-Up": 6000,
    Corporate: 10000,
    "Corporate +": 20000,
    Overseas: 6000,
    "Non-Profit Organization": 5500,
  };

  const [noOfYears, setNoOfYears] = useState(1);
  const [totalAmount, setTotalAmount] = useState(
    annualFees[membershipType] * noOfYears
  );

  const handleRadioChange = (event) => {
    setNoOfYears(parseInt(event.target.value));
  };

  // Update totalAmount when noOfYears changes
  useEffect(() => {
    setTotalAmount(annualFees[membershipType] * noOfYears);
  }, [noOfYears, membershipType]);

  useEffect(() => {
    const membershipCertificate = getAuthUserlocal().membershipCertificate;
    setPdfUrl(membershipCertificate);

    if (validUpto) {
      try {
        const currentDate = new Date();
        const validUptoDate = new Date(validUpto.replace(/-/g, "/"));

        if (!isNaN(validUptoDate.getTime())) {
          const differenceInTime =
            validUptoDate.getTime() - currentDate.getTime();
          const differenceInDays = Math.ceil(
            differenceInTime / (1000 * 3600 * 24)
          );

          const displayDays = differenceInDays <= 0 ? 0 : differenceInDays;
          setDaysLeft(displayDays);
        } else {
          console.error("Invalid date format for validUpto:", validUpto);
        }
      } catch (error) {
        console.error("Error parsing date:", error);
      }
    } else {
      console.error("validUpto is undefined");
    }
  }, [validUpto]);

  return (
    <>
      <Layout>
        <Layout2>
          <DashboardHeader />
          <h4 className="mt-3">
  {getAuthUserlocal().selectMembership !== "Digital User"
    ? getAuthUserlocal().selectMembership === "Collaboration"
      ? "Certificate of Collaboration"
      :getAuthUserlocal().selectMembership === "Arbitration"
      ? "Certificate of Arbitration"
      : "Certificate of Membership"
    : "Membership Services"}
</h4>

          <hr className="mt-3 mb-1" />

          {membershipType === "Digital User" ? (
            <div className="d-flex flex-column dashboardBlock">
              <div className="d-flex flex-column mt-1 mb-4 ms-2 ">
                <h5 className="mb-2">AECCI Membership & its Benefits,</h5>
                <span>
                  “Discover a world of possibilities and maximize your potential
                  with our flexible range of membership options, tailored to
                  meet your unique needs and goals.
                </span>
              </div>
              <AccordianSection />
            </div>
          ) : (
            <div className="d-flex flex-column dashboardBlock ">
              <h4 className="text-center align-content-center my-4 text-success">
                Congratulations for attaining your {membershipType === "Collaboration" ? "collaboration" : membershipType === "Arbitration" ?"Arbitration":"membership"} certificate! You
                are now the part of AECCI’s Members Fraternity. Access your
                certificate by clicking the tab below.
              </h4>
              <div
                style={{ position: "relative", width: "100%", height: "auto" }}
              >
                <div
                  style={{
                    textAlign: "center",
                    position: "absolute",
                    zIndex: 1,
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <span
                    className="fw-semibold fs-3 cursor-pointer"
                    style={{
                      color: isHovered ? "grey" : "white",
                      transition: "color 0.3s",
                    }}
                    onClick={() =>
                      openCertificateModal(
                        getAuthUserlocal().membershipCertificate
                      )
                    }
                    onMouseOver={() => setHovered(true)}
                    onMouseOut={() => setHovered(false)}
                  >
                    VIEW CERTIFICATE
                  </span>
                </div>
                <img
                  width="100%"
                  height="550"
                  src={
                    membershipType === "Collaboration"
                      ? dummyCollaborationCertificate
                      : dummyMembershipCertificate
                  }
                  alt=""
                  srcSet=""
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    objectFit: "cover",
                    objectPosition: "top",
                    filter: "brightness(70%)",
                    maxHeight: "550px", // Set a maximum height for the image
                    width: "100%", // Ensure the image takes the full width of its container
                  }}
                  className="desktop-image" // Add a class for desktop-specific styles
                />
              </div>
              {/* 
              //rendering Certificate modal here--------------------
              -------------------------------------------------------
              ------------------------------------------------------ */}
              <CertificateModal
                show={showCertificateModal}
                onHide={closeCertificateModal}
                certificate={certificateUrl}
                membershipType={membershipType}
              />

              <h3 className="text-center align-content-center mt-4 mb-2 text-danger pulse">
                ALERT..!
              </h3>
              <h5 className="text-center align-content-center mt-2 mb-4 text-danger">
                Your Membership subscription is valid for {daysLeft} days.
              </h5>
              <div className="text-center align-content-center">
                <button
                  className="bg-color p-2 px-4 rounded border-0 fw-semibold"
                  onClick={() => handleShow()}
                >
                  RENEW YOUR MEMBERSHIP
                </button>
              </div>

              <Modal
                show={show}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Membership Renewal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <Card>
                      <Card.Header>
                        {" "}
                        <h5>For Renewal fill the below form</h5>
                      </Card.Header>
                      <Card.Body>
                        <div className="d-flex justify-content-between">
                          <div className="w-50">
                            <h6>
                              Type of Memb :
                              <span className="text-secondary">
                                {" "}
                                {getAuthUserlocal().selectMembership}
                              </span>
                            </h6>

                            <h6>
                              Memb NO. :
                              <span className="text-secondary">
                                {" "}
                                {getAuthUserlocal().memberShipNo}
                              </span>
                            </h6>
                          </div>
                          <div className="w-50">
                            <h6>
                              Amount :{" "}
                              <span className="text-success">
                                ₹ {totalAmount}/-
                              </span>
                            </h6>
                            <h6>
                              Company Name :
                              <span className="text-secondary">
                                {" "}
                                {getAuthUserlocal().companyName}
                              </span>
                            </h6>
                          </div>
                        </div>

                        <hr />

                        <div>
                          <h6>Package Selection :</h6>
                          <Form className="d-flex">
                            <div key="radio" className="mb-3">
                              <Form.Check
                                inline
                                label="1 Year"
                                name="group1"
                                type="radio"
                                value="1"
                                onChange={handleRadioChange}
                                checked={noOfYears === 1}
                              />
                            </div>
                            <div key="radio" className="mb-3">
                              <Form.Check
                                inline
                                label="2 Year"
                                name="group1"
                                type="radio"
                                value="2"
                                onChange={handleRadioChange}
                                checked={noOfYears === 2}
                              />
                            </div>
                            <div key="radio" className="mb-3">
                              <Form.Check
                                inline
                                label="3 Year"
                                name="group1"
                                type="radio"
                                value="3"
                                onChange={handleRadioChange}
                                checked={noOfYears === 3}
                              />
                            </div>
                            <div key="radio" className="mb-3">
                              <Form.Check
                                inline
                                label="5 Year"
                                name="group1"
                                type="radio"
                                value="5"
                                onChange={handleRadioChange}
                                checked={noOfYears === 5}
                              />
                            </div>
                          </Form>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </Modal.Body>
                <Modal.Footer className="text-center justify-content-center">
                  <Button
                    variant="success"
                    onClick={handleMembRenew}
                    disabled={!proccedPaymentFlag}
                  >
                    PROCEED PAYMENT
                  </Button>
                </Modal.Footer>
              </Modal>
              <hr className="mt-4 mb-4" />
            </div>
          )}
        </Layout2>
      </Layout>
    </>
  );
};

export default MembershipServices;

export const CertificateModal = ({ show, onHide, certificate, membershipType }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{membershipType === "Collaboration" ? "Certificate Of Collaboration" :membershipType === "Arbitration" ?"Certificate Of Arbitration": "Membership Certificate"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <iframe
            src={certificate}
            title="Membership Certificate"
            width="100%"
            height="700px"
            frameBorder="0"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
