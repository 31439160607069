import React, { useEffect, useState } from "react";
import Layout2 from "../../../components/Layouts/Layouts/Layout2";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import Layout from "../../../components/Layouts/Layouts/Layout";
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import "./b2b.css";
import { getAuthUserlocal } from "../../../store/services";
import axios from "axios";
import { getURLbyEndPointV2 } from "../../../store/api";

const B2bInterestedClient = () => {
  const [participantList, setParticipateList] = useState([]);

  const colors = [
    "#FFF9F9", // Light pink
    "#F5FFFF", // Light cyan
    "#FFF5E1", // Light peach
    "#F0FFF0", // Honeydew (very light green)
    "#FFF0F5", // Lavender blush
    "#F0F8FF", // Alice blue
    "#F5F5DC", // Beige
    "#F5FFFA", // Mint cream
    "#FAFAD2", // Light goldenrod yellow
    "#FFFFF0", // Ivory
    "#FDF5E6", // Old lace (light beige)
    "#F0FFFF", // Azure (very light cyan)
  ];

  const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const fetchParticipateList = async () => {
    try {
      const userId = getAuthUserlocal()._id;
      const country = getAuthUserlocal().country;

      const response = await axios.get(
        getURLbyEndPointV2("getParticipateList") + userId
      );
      if (response.status === 200) {
        const data = response.data;
        setParticipateList(data.data);
      } else {
      }
    } catch (error) {
      console.error("Error fetching Event data:", error);
    }
  };

  useEffect(() => {
    fetchParticipateList();
  }, []);

  return (
    <>
      <Layout>
        <Layout2>
          <DashboardHeader />
          <div className="">
            <div>
              <div className="d-flex align-items-center">
                <h4 className="py-4">INTERESTED PARTICIPATE LIST</h4>
              </div>
              <hr className="mt-1 mb-1" />
            </div>
            <div style={{ backgroundColor: "#F4F4F9" }} className="p-3 rounded">
              <h3 className="text-center text-bold">AECCI B2B EVENT 2024</h3>
              <hr className="mx-auto w-75"/>
              <div>
              <p className="mt-3">
                We are pleased to provide a list of registered individuals,
                manufacturers, exporters, and importers. This list is based on
                the registrations received and approved by our membership desk.
                Please note that this list is realistic but tentative and may
                not reflect the exact number of interested participants from
                your country.
              </p>
              <p>
                <strong>Daily Meeting Slots Available:</strong> <br />
                Minimum: 10 business slots and Maximum: 16 business slots
              </p>

              <p>
                This allocation ensures that each country has a fair opportunity
                to engage in business meetings.
              </p>

              <p className="text-muted">
                Note: The number of available slots may vary based on daily
                registrations and approvals.
              </p>
              </div>
              <div className="my-2 fs-6 d-flex flex-row gap-2 w-100">
                <Button className="flex-grow-1">Day 1</Button>
                <Button className="flex-grow-1" disabled>
                  Day 2
                </Button>
                <Button className="flex-grow-1" disabled>
                  Day 3
                </Button>
              </div>

              <div className="mt-3">
                <div className="masonry-grid">
                  {participantList.map((item, index) => (
                    <div key={index} className="masonry-item">
                      <Card
                        style={{
                          backgroundColor: getRandomColor(),
                          borderRadius: "10px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <Card.Body>
                          <Card.Title className="fs-5 fw-bold text-center">
                            {item.companyName
                              ? item.companyName
                              : `${item.name} (${item.profession})`}
                          </Card.Title>

                          <Card.Text className="text-center my-2">
                            <Badge bg="primary" className="px-3 py-1">
                              {item.entity}
                            </Badge>
                          </Card.Text>

                          {/* <Card.Text className="text-center my-3">
                            <span className="fw-semibold">MembershipNo: </span>
                            AECCI/MEM/123
                          </Card.Text> */}

                          <Card.Text className="text-center my-3">
                            <span className="fw-semibold">Purpose: </span>
                            {item.userMessage}
                          </Card.Text>

                          <Card.Text className="text-center text-muted text-xs">
                            Slot Not yet confirmed
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Layout2>
      </Layout>
    </>
  );
};

export default B2bInterestedClient;
