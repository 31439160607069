import React, { useState } from "react";
import Layout from "../../components/Layouts/Layouts/Layout";
import Layout2 from "../../components/Layouts/Layouts/Layout2";
import DashboardHeader from "../../components/basic/DashboardHeader";
import {
  Row,
  Col,
  Card,
  Button,
  Modal,
  Tab,
  Nav,
  CloseButton,
} from "react-bootstrap";
import { FaTimes } from "react-icons/fa"; // Importing the close icon
import collabimg1 from "../../../src/assets/CollobrationPanel/CollabImg6.png";
import collabimg2 from "../../../src/assets/CollobrationPanel/CollabImg2.png";
import collabimg3 from "../../../src/assets/CollobrationPanel/CollabImg7.png";
import collabimg4 from "../../../src/assets/CollobrationPanel/CollabImg4.png";
import collabimg5 from "../../../src/assets/CollobrationPanel/CollabImg5.png";
import collabimg6 from "../../../src/assets/CollobrationPanel/CollabImg1.png";
import collabimg7 from "../../../src/assets/CollobrationPanel/CollabImg3.png";
import collabRM1 from "../../../src/assets/CollobrationPanel/CollabrationRM/CollabrationRM1.png";
import collabRM2 from "../../../src/assets/CollobrationPanel/CollabrationRM/CollabrationRM2.png";
import collabRM3 from "../../../src/assets/CollobrationPanel/CollabrationRM/CollabrationRM3.png";
import collabRM4 from "../../../src/assets/CollobrationPanel/CollabrationRM/CollabrationRM4.png";
import collabRM5 from "../../../src/assets/CollobrationPanel/CollabrationRM/CollabrationRM5.png";
import collabRM6 from "../../../src/assets/CollobrationPanel/CollabrationRM/CollabrationRM6.png";
import collabRM7 from "../../../src/assets/CollobrationPanel/CollabrationRM/CollabrationRM7.png";
import countryCollab from "../../../src/assets/CollobrationPanel/CountryCollab/collabration1.png";
import infoImage1 from "../../../src/assets/CollobrationPanel/CountryCollab/Infoimg1.png";
import Brazillogo from "../../../src/assets/CollobrationPanel/collabrationlogos/Brazil.png";
import Cambodialogo from "../../../src/assets/CollobrationPanel/collabrationlogos/Cambodia.png";
import Denmarklogo from "../../../src/assets/CollobrationPanel/collabrationlogos/Denmark.png";
import Egyptlogo from "../../../src/assets/CollobrationPanel/collabrationlogos/Egypt.png";

import infoImage2 from "../../assets/CollobrationPanel/InternationalCollbrationsTamp/1.png";
import BrazilCollab from "../../assets/CollobrationPanel/InternationalCollbrationsTamp/BrazilCollab.png";
import CambodiaCollab from "../../assets/CollobrationPanel/InternationalCollbrationsTamp/CambodiaCollab.png";
import DenmarkCollab from "../../assets/CollobrationPanel/InternationalCollbrationsTamp/DenmarkCollab.png";
import EgyptCollab from "../../assets/CollobrationPanel/InternationalCollbrationsTamp/EgyptCollab.png";

const CollaborationPortal = () => {
  const cardDetails = [
    {
      id: 1,
      date: "5th-6th October 2023",
      colour: "#3c2c63",
      imgUrl: collabimg1,
      readMoreImg: collabRM1,
      companyUrl: "https://rigacomm.com/lv/",
    },
    {
      id: 2,
      date: "October 04,05, 2023",
      colour: "#d41c25",
      imgUrl: collabimg2,
      readMoreImg: collabRM2,
      companyUrl: "https://companywebsite.com",
    },
    {
      id: 3,
      date: "November 07-09, 2023",
      colour: "#d69178",
      imgUrl: collabimg3,
      readMoreImg: collabRM3,
      companyUrl: "https://www.speciality.ae/",
    },
    {
      id: 4,
      date: "November 7-9, 2023",
      colour: "#b550a2",
      imgUrl: collabimg4,
      readMoreImg: collabRM4,
      companyUrl: "https://www.prime-expo.com/",
    },
    {
      id: 5,
      date: "November 7-9, 2023",
      colour: "#000000",
      imgUrl: collabimg5,
      readMoreImg: collabRM5,
      companyUrl: "https://www.gulfoodmanufacturing.com/",
    },
    {
      id: 6,
      date: "November 7-9, 2023",
      colour: "#0282c2",
      imgUrl: collabimg6,
      readMoreImg: collabRM6,
      companyUrl: "https://companywebsite.com",
    },
    {
      id: 7,
      date: "July 4-6, 2024",
      colour: "#a81d24",
      imgUrl: collabimg7,
      readMoreImg: collabRM7,
      companyUrl: "https://companywebsite.com",
    },
  ];

  const collabCountry = [
    {
      id: 1,
      country: "Brazil",
      title: "ABDO ADVOGADOS",
      img: Brazillogo,
      info: (
        <div>
          <div>
            <strong>♦Traders’ Support:</strong> A partnership for legal
            assistance to traders in Brazil and India.
          </div>
          <div>
            <strong>♦Empowering Trade: </strong> AECCI and Abdo Advogados
            collaborate to empower exporters and importers.{" "}
          </div>
          <div>
            <strong>♦Abdo Advogados: </strong>A top international law firm in
            Brazil.
          </div>
          <div>
            <strong>♦Legal Support: </strong>They offer expert guidance on legal
            matters in their respective countries.
          </div>
          <div>
            <strong>
              This International collaboration supports trade industry exporters
              and importers..{" "}
            </strong>
          </div>
        </div>
      ),
      infoimg: BrazilCollab,
    },
    {
      id: 2,
      country: "Cambodia",
      title: "BNG LEGAL LAW FIRM",
      img: Cambodialogo,
      info: (
        <div>
          <div>
            ♦ BNG Legal: Leading Cambodian law firm, known for innovative legal
            services.
          </div>
          <div>
            ♦AECCI and BNG Legal collaborate to assist traders and businesses in
            India and Cambodia.
          </div>
          <div>
            ♦Partnership focuses on joint trade initiatives to benefit members
            in both countries.
          </div>
          <div>
            ♦Our partnership aims to provide empowerment and support to trade
            industry exporters and importers, facilitating their business
            activities.
          </div>
        </div>
      ),
      infoimg: CambodiaCollab,
    },

    {
      id: 3,
      country: "Denmark",
      title: "Fabritius Tengnagel & Heine",
      img: Denmarklogo,
      info: (
        <div>
          <div>
            <strong>♦Renowned Partner: </strong> AECCI collaborates with
            Fabritius Tengnagel & Heine, a premier Danish law firm
          </div>
          <div>
            <strong>♦Dedicated Trade Support: </strong> Joint commitment to
            provide tailored solutions for businesses in trade, resolving
            disputes effectively.
          </div>
          <div>
            <strong>♦ Empowering Trade: </strong> Partnership focuses on
            supporting exporters and importers, fostering smoother trade
            operations.
          </div>
          <div>
            <strong>♦Legal Expertise:</strong>Leveraging Fabritius Tengnagel &
            Heine’s expertise for robust legal support in respective countries.
          </div>
          <div>
            <strong>
              This international collaboration supports trade industry exporters
              and importers{" "}
            </strong>
          </div>
        </div>
      ),
      infoimg: DenmarkCollab,
    },
    {
      id: 4,
      country: "Egypt",
      title: "SADANY & KHALIFA LAW FIRM",
      img: Egyptlogo,
      info: (
        <div>
          <div>
            ♦ Sadany & Khalifa Law Firm is one of the top international law
            firms in Egypt{" "}
          </div>
          <div>
            ♦Our partnership dedicated to assisting traders in resolving
            challenges and disputes pertaining our Region{" "}
          </div>
          <div>
            ♦AECCI proudly collaborates with Sadany & Khalifa Law Firm in Egypt
            to offer invaluable assistance to traders in the face of challenges
            and disputes.{" "}
          </div>
          <div>
            <strong>
              Our Collaboration shall empower and Support exporters and
              importers in the trade industry to facilitate their Trade
              Business.
            </strong>
          </div>
        </div>
      ),
      infoimg: EgyptCollab,
    },
    {
      id: 5,
      country: "Mexico",
      title: "MEXITÁNEA SA DE CV (New Markets)",
      img: "",
      info: "",
      infoimg: BrazilCollab,
    },

    {
      id: 6,
      country: "Tanzania",
      title: "Victory Attorneys & Consultants",
      img: "",
      info: "",
      infoimg: BrazilCollab,
    },
    {
      id: 7,
      country: "Bahamas",
      title: "PARRIS WHITTAKER",
      img: "",
      info: "",
      infoimg: BrazilCollab,
    },
    {
      id: 8,
      country: "Bosnia & Herzegovina",
      title: "ADVOKATI CVIJANOVIC",
      img: "",
      info: "",
      infoimg: BrazilCollab,
    },
    {
      id: 9,
      country: "Bangladesh",
      title: "CREDENCE LP",
      img: "",
      info: "",
      infoimg: BrazilCollab,
    },
    {
      id: 10,
      country: "Algeria",
      title: "GRANT THORNTON SPA",
      img: "",
      info: "",
      infoimg: BrazilCollab,
    },
    {
      id: 11,
      country: "Nigeria",
      title: "VAZI LEGAL",
      img: "",
      info: "",
      infoimg: BrazilCollab,
    },
    {
      id: 12,
      country: "Vietnam",
      title: "HM&P LAW FIRM",
      img: "",
      info: "",
      infoimg: BrazilCollab,
    },
    {
      id: 13,
      country: "United Kingdom",
      title: "Go Exporting",
      img: "",
      info: "",
      infoimg: BrazilCollab,
    },
    {
      id: 14,
      country: "China",
      title: "QINGDAO DAYING LEGAL SERVICES CO. LTD",
      img: "",
      info: "",
      infoimg: BrazilCollab,
    },
    {
      id: 15,
      country: "Pakistan",
      title: "M R LEGAL INN",
      img: "",
      info: "",
      infoimg: BrazilCollab,
    },
    {
      id: 16,
      country: "Oman",
      title: "Al Alawi & Co",
      img: "",
      info: "",
      infoimg: BrazilCollab,
    },
    {
      id: 17,
      country: "Turkey",
      title: "Att. Ömer Faruk Gök NMR Legal Consultant",
      img: "",
      info: "",
      infoimg: BrazilCollab,
    },
    {
      id: 18,
      country: "Greece",
      title: "K. Tsiaga & Partners (Thesis Law firm)",
      img: "",
      info: "",
      infoimg: BrazilCollab,
    },
    {
      id: 19,
      country: "Thailand",
      title: "iLawAsia ",
      img: "",
      info: "",
      infoimg: BrazilCollab,
    },
    {
      id: 20,
      country: "Ethiopia",
      title: "Dablo Law Firm",
      img: "",
      info: "",
      infoimg: BrazilCollab,
    },
    {
      id: 21,
      country: "South Sudan",
      title: "Legal Line Law Chambers",
      img: "",
      info: "",
    },
    {
      id: 22,
      country: "Sri Lanka",
      title: "Juliyans Associates Law Firm ",
      img: "",
      info: "",
    },
    {
      id: 23,
      country: "UAE",
      title: "True Law Partners ",
      img: "",
      info: "",
    },
    {
      id: 24,
      country: "Uzbekistan",
      title: "PraeLegal Law Firm",
      img: "",
      info: "",
    },
    {
      id: 25,
      country: "Jordan",
      title: " Al-Khair Legal Attorneys",
      img: require("../../../src/assets/CollobrationPanel/CountryCollab/collabration1.png"),
      info: (
        <div>
          <div>
            We are excited to announce a dynamic partnership between AECCI and
            Al-Khair Legal Attorneys, led by the distinguished Founder and
            Managing Partner Mohammad Khair Al-Sabbagh. We aim on expanding our
            business reach and delivering superior legal support to exporters
            and importers.
          </div>
          <div>
            This partnership allows us to combine our expertise, enhancing our
            services and delivering tailored, high-quality legal solutions
            specifically designed to meet the unique needs of traders.This
            partnership marks a pivotal advancement in strengthening our market
            presence and delivering exceptional legal services across various
            sectors, including business and international law. Our focus areas
            encompass corporate and commercial law, investment, tax law, and
            alternative dispute resolution.
          </div>
        </div>
      ),
      infoimg: infoImage1,
    },
  ];
  const [showModal, setShowModal] = useState(false);
  const [modalImg, setModalImg] = useState("");
  const [activeTab, setActiveTab] = useState(collabCountry[0].id);
  const [showCards, setShowCards] = useState(false);

  const handleShowModal = (imgUrl) => {
    setModalImg(imgUrl);
    setShowModal(true);
  };

  const handleTabChange = (id) => {
    setShowCards(true);
    setActiveTab(id);
  };

  return (
    <Layout>
      <Layout2>
        <DashboardHeader />
        <h4 className="mb-3">Collaboration Portal</h4>
        <hr />
        <div className="d-flex flex-column dashboardBlock">
          <Tab.Container activeKey={activeTab}>
            <Row className="no-margin no-gutters">
              {collabCountry.map(({ id, country }) => (
                <Col sm={3} className="p-1" key={id}>
                  <Nav variant="pills" className="flex-column border">
                    <Nav.Item>
                      <Nav.Link
                        onClick={() => handleTabChange(id)}
                        style={{
                          backgroundColor: "transparent",
                          color: "#000",
                        }}
                        className={activeTab === id ? "active" : ""}
                      >
                        <strong>{country}</strong>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
              ))}
            </Row>
            <hr />
            <Tab.Content>
              {showCards &&
                collabCountry.map((countryDetails) => (
                  <Tab.Pane
                    eventKey={countryDetails.id}
                    key={countryDetails.id}
                  >
                    <LawFirmComponent {...countryDetails} />
                  </Tab.Pane>
                ))}
            </Tab.Content>
          </Tab.Container>

          {/* <Row>
              {cardDetails.map((details) => (
                <Col xs={12} md={6} lg={4} className="my-2" key={details.id}>
                  <CollaborationCard {...details} onClick={handleShowModal} />
                </Col>
              ))}
            </Row> */}

          <ImageModel
            show={showModal}
            image={modalImg}
            onClose={() => setShowModal(false)}
          />
        </div>
      </Layout2>
    </Layout>
  );
};

export default CollaborationPortal;

const LawFirmComponent = ({ title, img, country, infoimg, info }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (imgUrl) => {
    setShowModal(true);
  };

  return (
    <>
      <div className="d-flex flex-row flex-container border">
        <div style={{ backgroundColor: "#05343d" }}>
          <div className="px-3 pt-3">
            <img
              decoding="async"
              className="aligncenter"
              alt={title}
              width="310px"
              height="200px"
              src={img}
            />
          </div>
          <div className="d-flex flex-column align-items-center">
            <div className="text-white pt-2">
              <h5 className="text-center">{title}</h5>
              <h6 className="text-center">({country})</h6>
            </div>
            <div className="pt-4 pb-3">
              <Button
                variant="primary"
                onClick={() => handleShowModal(infoimg)}
              >
                Read More
              </Button>
            </div>
          </div>
        </div>
        <div className="p-4">
          <h4>
            AECCI Collaboration with {title} in {country}
          </h4>
          <div style={{ textAlign: "justify", color: "#000" }}>{info}</div>
        </div>
      </div>
      <ImageModel
        show={showModal}
        image={infoimg}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};

const CollaborationCard = ({
  imgUrl,
  colour,
  date,
  readMoreImg,
  companyUrl,
  onClick,
}) => (
  <Card
    className="text-center shadow-sm border border-dark"
    style={{
      backgroundColor: colour,
      color: "white",
      borderRadius: "10px",
    }}
  >
    <Card.Img
      src={imgUrl}
      onClick={() => window.open(companyUrl, "_blank")}
      alt="Company logo"
      className="img-responsive p-1 card-img"
      style={{
        height: "150px",
        cursor: "pointer",
        objectFit: "cover",
        backgroundColor: "white",
        padding: "1px",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
      }}
    />
    <Card.Body>
      <Card.Text className="font-weight-bold" style={{ fontSize: "14px" }}>
        {date}
      </Card.Text>
      <Button
        variant="light"
        size="sm"
        style={{ marginTop: "10px" }}
        onClick={() => onClick(readMoreImg)}
      >
        Read more
      </Button>
    </Card.Body>
  </Card>
);

const ImageModel = ({ show, image, onClose }) => {
  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        className="custom-modal"
        backdrop="static"
      >
        <Modal.Body>
          <img
            src={image}
            alt="Preview"
            style={{ width: "600px", height: "auto" }}
          />
        </Modal.Body>
        <button type="button" className="close-button" onClick={onClose}>
          <FaTimes size={24} />
        </button>
      </Modal>
      <style jsx>{`
        .custom-modal .modal-dialog {
          max-width: 50%; /* Adjust width as needed */
          margin: 30px auto; /* Center modal */
        }
        .custom-modal .modal-content {
          background-color: transparent;
          border: none;
          position: relative; /* Ensure the button is relative to the modal */
        }
        .close-button {
          position: fixed; /* Fix to the viewport */
          top: 1rem; /* Adjust as needed */
          right: 2rem; /* Adjust as needed */
          background: transparent;
          border: none;
          cursor: pointer;
          z-index: 1050; /* Ensure it is above other content */
        }
        .close-button:hover {
          color: white;
        }
      `}</style>
    </>
  );
};
