import React, { useState } from "react";
import {
  Row,
  Col,
  Modal,
  Button,
  Card,
  Container,
  CloseButton,
} from "react-bootstrap";
import Layout from "../../../components/Layouts/Layouts/Layout";
import Layout2 from "../../../components/Layouts/Layouts/Layout2";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import panelImg1 from "../../../assets/PanelImages/PanelImg1.png";
import panelImg2 from "../../../assets/PanelImages/PanelImg2.png";
import panelImg3 from "../../../assets/PanelImages/PanelImg3.png";
import panelImg4 from "../../../assets/PanelImages/PanelImg4.png";
import panelImg5 from "../../../assets/PanelImages/PanelImg5.png";
import panelImg6 from "../../../assets/PanelImages/PanelImg6.png";
import panelImg7 from "../../../assets/PanelImages/PanelImg7.png";
import panelImg8 from "../../../assets/PanelImages/Anilkumar.png";
import panelImg9 from "../../../assets/PanelImages/PanelImg9.png";
import panelImg10 from "../../../assets/PanelImages/PanelImg10.png";
import panelImg11 from "../../../assets/PanelImages/PanelImg11.jpg";
import panelImg12 from "../../../assets/PanelImages/RaghvendraChauhan.png";
import panelImg13 from "../../../assets/PanelImages/SivaRao.png";
import panelImg14 from "../../../assets/PanelImages/PanelImg14.jpg";
import panelImg15 from "../../../assets/PanelImages/GeetaLuthra.png";
import panelImg16 from "../../../assets/PanelImages/LSreerangaraju.png";
import panelImg17 from "../../../assets/PanelImages/SubhashGupta.png";

import QuickLinks from "../../../components/basic/QuickLinks";

const PanelName = () => {
  const panelData = [
    {
      id: 1,
      panelTitle: "MR .ANIL KUMAR JAIN",
      Qualifications:
        "B.Sc. from Allahabad University; Bachelor of Engineering (Electrical) with 1st class Honours from University of Roorkee (now IIT Roorkee); Masters’ Diploma in Public Administration from Indian Institute of Public Administration, Govt. of India, New Delhi.",
      Empanelment:
        "A senior Finance Professional with 47 years of expertise. Empanelment: Arbitrator empaneled with National Stock Exchange (NSE) and Bombay Stock Exchange (BSE).",
      Experience:
        "37 years with CPWD, Govt. of India; post-retirement, advised IITs and universities on major projects including PMSSY hospitals and Commonwealth Games stadia.",
      Expertise:
        "Expert in DPR preparation, hilly terrain construction, contract management, project monitoring, and energy efficiency in infrastructure projects.",

      imageSrc: panelImg8,
    },
    {
      id: 2,
      panelTitle: "JUSTICE RAGHVENDRA SINGH CHAUHAN",
      Qualifications: "A qualified Chartered Accountant.",
      Empanelment:
        "A senior Finance Professional with 47 years of expertise. Empanelment: Arbitrator empaneled with National Stock Exchange (NSE) and Bombay Stock Exchange (BSE).",
      Experience:
        "Led numerous Merger and Acquisition deals for large corporate clients on both national and international levels.",
      Expertise:
        "Extensive practice in finance markets with a strong understanding of Taxation laws, Corporate laws, Financial Management, and accounting.",

      imageSrc: panelImg12,
    },
    {
      id: 3,
      panelTitle: "JUSTICE DR.B.SIVA SANKARA RAO",
      Qualifications: "A qualified Chartered Accountant.",
      Empanelment:
        "A senior Finance Professional with 47 years of expertise. Empanelment: Arbitrator empaneled with National Stock Exchange (NSE) and Bombay Stock Exchange (BSE).",
      Experience:
        "Led numerous Merger and Acquisition deals for large corporate clients on both national and international levels.",
      Expertise:
        "Extensive practice in finance markets with a strong understanding of Taxation laws, Corporate laws, Financial Management, and accounting.",

      imageSrc: panelImg13,
    },
    {
      id: 4,
      panelTitle: "Dr Subhash C Gupta",
      Qualifications: "Ph D (Law); Ph D (Management)",
      Empanelment:
        "A senior Finance Professional with 47 years of expertise. Empanelment: Arbitrator empaneled with National Stock Exchange (NSE) and Bombay Stock Exchange (BSE).",
      Experience:
        "Led numerous Merger and Acquisition deals for large corporate clients on both national and international levels.",
      Expertise:
        "Extensive practice in finance markets with a strong understanding of Taxation laws, Corporate laws, Financial Management, and accounting.",
      imageSrc: panelImg17,
    },

    {
      id: 5,
      panelTitle: "ADV.GEETA LUTHRA",
      Qualifications: "A qualified Chartered Accountant.",
      Empanelment:
        "A senior Finance Professional with 47 years of expertise. Empanelment: Arbitrator empaneled with National Stock Exchange (NSE) and Bombay Stock Exchange (BSE).",
      Experience:
        "Independent arbitrator with 42 years' experience in various law fields. Additional Advocate General for Haryana, with extensive arbitration expertise.",
      Expertise:
        "Extensive practice in finance markets with a strong understanding of Taxation laws, Corporate laws, Financial Management, and accounting.",

      imageSrc: panelImg15,
    },
    {
      id: 6,
      panelTitle: "MR.L V SREERANGARAJU",
      Qualifications: "A qualified Chartered Accountant.",
      Empanelment:
        "A senior Finance Professional with 47 years of expertise. Empanelment: Arbitrator empaneled with National Stock Exchange (NSE) and Bombay Stock Exchange (BSE).",
      Experience:
        "Led numerous Merger and Acquisition deals for large corporate clients on both national and international levels.",
      Expertise:
        "Extensive practice in finance markets with a strong understanding of Taxation laws, Corporate laws, Financial Management, and accounting.",

      imageSrc: panelImg16,
    },
    {
      id: 7,
      panelTitle: "CA Rajendra Karnmal Bhuta",
      Qualifications: "A qualified Chartered Accountant.",
      Empanelment:
        "A senior Finance Professional with 47 years of expertise. Empanelment: Arbitrator empaneled with National Stock Exchange (NSE) and Bombay Stock Exchange (BSE).",
      Experience:
        "Led numerous Merger and Acquisition deals for large corporate clients on both national and international levels.",
      Expertise:
        "Extensive practice in finance markets with a strong understanding of Taxation laws, Corporate laws, Financial Management, and accounting.",

      imageSrc: panelImg1,
    },
    {
      id: 8,
      panelTitle: "DR. Sudharanjan Sahu",
      Qualifications: "A qualified Chartered Accountant.",
      Empanelment:
        "A senior Finance Professional with 47 years of expertise. Empanelment: Arbitrator empaneled with National Stock Exchange (NSE) and Bombay Stock Exchange (BSE).",
      Experience:
        "Led numerous Merger and Acquisition deals for large corporate clients on both national and international levels.",
      Expertise:
        "Extensive practice in finance markets with a strong understanding of Taxation laws, Corporate laws, Financial Management, and accounting.",

      imageSrc: panelImg2,
    },
    {
      id: 9,
      panelTitle: "CA.Pawan Kumar Agarwal",
      Qualifications: "A qualified Chartered Accountant.",
      Empanelment:
        "A senior Finance Professional with 47 years of expertise. Empanelment: Arbitrator empaneled with National Stock Exchange (NSE) and Bombay Stock Exchange (BSE).",
      Experience:
        "Led numerous Merger and Acquisition deals for large corporate clients on both national and international levels.",
      Expertise:
        "Extensive practice in finance markets with a strong understanding of Taxation laws, Corporate laws, Financial Management, and accounting.",

      imageSrc: panelImg3,
    },
    {
      id: 10,
      panelTitle: "ADV. Kushnuma Khan",
      Qualifications: "A qualified Chartered Accountant.",
      Empanelment:
        "A senior Finance Professional with 47 years of expertise. Empanelment: Arbitrator empaneled with National Stock Exchange (NSE) and Bombay Stock Exchange (BSE).",
      Experience:
        "Led numerous Merger and Acquisition deals for large corporate clients on both national and international levels.",
      Expertise:
        "Extensive practice in finance markets with a strong understanding of Taxation laws, Corporate laws, Financial Management, and accounting.",

      imageSrc: panelImg4,
    },
    {
      id: 11,
      panelTitle: "ADV.Pradeep Vital Sawant",
      Qualifications: "A qualified Chartered Accountant.",
      Empanelment:
        "A senior Finance Professional with 47 years of expertise. Empanelment: Arbitrator empaneled with National Stock Exchange (NSE) and Bombay Stock Exchange (BSE).",
      Experience:
        "Led numerous Merger and Acquisition deals for large corporate clients on both national and international levels.",
      Expertise:
        "Extensive practice in finance markets with a strong understanding of Taxation laws, Corporate laws, Financial Management, and accounting.",

      imageSrc: panelImg5,
    },
    {
      id: 12,
      panelTitle: "ADV.Anant Chawre",
      Qualifications: "A qualified Chartered Accountant.",
      Empanelment:
        "A senior Finance Professional with 47 years of expertise. Empanelment: Arbitrator empaneled with National Stock Exchange (NSE) and Bombay Stock Exchange (BSE).",
      Experience:
        "Led numerous Merger and Acquisition deals for large corporate clients on both national and international levels.",
      Expertise:
        "Extensive practice in finance markets with a strong understanding of Taxation laws, Corporate laws, Financial Management, and accounting.",

      imageSrc: panelImg6,
    },
    {
      id: 13,
      panelTitle: "ADV.Rupali Akolkar",
      Qualifications: "A qualified Chartered Accountant.",
      Empanelment:
        "A senior Finance Professional with 47 years of expertise. Empanelment: Arbitrator empaneled with National Stock Exchange (NSE) and Bombay Stock Exchange (BSE).",
      Experience:
        "Led numerous Merger and Acquisition deals for large corporate clients on both national and international levels.",
      Expertise:
        "Extensive practice in finance markets with a strong understanding of Taxation laws, Corporate laws, Financial Management, and accounting.",

      imageSrc: panelImg7,
    },
  ];

  const [selectedItem, setSelectedItem] = useState(null);

  const openModal = (id) => {
    const selectedCard = panelData.find((card) => card.id === id);
    setSelectedItem(selectedCard);
  };

  const closeModal = () => {
    setSelectedItem(null);
  };

  return (
    <Layout>
      <Layout2>
        <DashboardHeader />

        <div>
          <h4 className="pt-4 mb-3">PANEL</h4>
          <hr />
          <h5>
            <i>
              Meet our highly experienced empaneled arbitrators at AECCI-IAC who
              are dedicated to providing efficient and effective resolution of
              disputes.
            </i>
          </h5>

          <CircleImage panelData={panelData} openModal={openModal} />

          {/* Modal */}
          <Modal show={!!selectedItem} onHide={closeModal} size="ls" centered>
            {/* <Modal.Header closeButton></Modal.Header> */}
            <Modal.Body className="d-flex justify-content-center position-relative">
              <CloseButton
                variant="danger"
                className="position-absolute top-0 end-0 mt-4 me-4"
                onClick={closeModal}
              />
              {selectedItem && (
                <div className="d-flex flex-column align-items-center modal-card display-card">
                  <img
                    src={selectedItem.imageSrc}
                    alt={selectedItem.panelTitle}
                    className="img-fluid border"
                    style={{
                      maxWidth: "300px",
                      borderRadius: "8px",
                      width: "120px",
                      borderColor: "#ccc", // Customize border color
                      borderWidth: "2px", // Customize border width
                      borderStyle: "solid",
                    }}
                  />
                  <div className="ml-1 d-flex flex-column">
                    <h3 className="text-center p-2 fs-4">
                      {selectedItem.panelTitle}
                    </h3>
                    <p>
                      <strong>Qualifications:</strong>{" "}
                      {selectedItem.Qualifications}
                    </p>
                    <p>
                      <strong>Empanelment:</strong> {selectedItem.Empanelment}
                    </p>
                    <p>
                      <strong>Experience:</strong> {selectedItem.Experience}
                    </p>
                    <p>
                      <strong>Expertise:</strong> {selectedItem.Expertise}
                    </p>
                  </div>
                </div>
              )}
            </Modal.Body>
          </Modal>
        </div>
        <QuickLinks></QuickLinks>
      </Layout2>

      {/* Inline CSS */}
    </Layout>
  );
};

const CircleImage = ({ panelData, openModal }) => {
  return (
    <Container
      className="d-flex flex-wrap justify-content-around gap-3 pt-2 pb-2"
      style={{ backgroundColor: "#ebeff5" }}
    >
      {panelData.map((panel) => (
        <Card
          className="text-center shadow-sm"
          style={{ width: "180px", cursor: "pointer" }}
          key={panel.id}
          onClick={() => openModal(panel.id)}
        >
          <div
            className="d-flex justify-content-center align-items-center mb-2"
            style={{ marginBottom: "8px" }} // Replicates margin-bottom: 8px
          >
            <Card.Img
              variant="top"
              src={panel.imageSrc}
              alt={panel.panelTitle}
              className="img-responsive p-2"
              style={{
                width: "120px", // Sets the width of the image
                borderRadius: "50%", // Makes the image circular
                objectFit: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                height: "120px", // Maintains aspect ratio
                filter: "grayscale(100%)",
              }}
            />
          </div>
          <Card.Body
            style={{
              height: "60px", // Adjust this value to reduce the height as needed
              overflow: "hidden", // Optional: hides overflow if content exceeds height
              paddingTop: "0",
              paddingBottom: "0",
            }}
          >
            <Card.Text
              className="font-weight-bold"
              style={{ fontSize: "12px" }}
            >
              {panel.panelTitle}
            </Card.Text>
          </Card.Body>
        </Card>
      ))}
    </Container>
  );
};

export default PanelName;
